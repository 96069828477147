import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {AuthenticationService} from "../../services/Authentication/authentication.service";

@Component({
  selector: 'app-not-found',
  template: `<p>Redirecting...</p>`,
  standalone: true
})
export class NotFoundComponent implements OnInit {
  constructor(private router: Router, private authService: AuthenticationService) {
  }

  ngOnInit() {
    this.authService.isAuthenticated$.subscribe(
      isAuthenticated => {
        if (isAuthenticated) {
          this.authService.homePage()
        } else {
          this.authService.logout();
        }
      }
    );
  }
}
