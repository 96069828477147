import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from "../../services/Authentication/authentication.service";
import {ActivatedRoute, Router} from "@angular/router";
import {PermissionFlagsService} from "../../services/Authentication/permissionFlags.service";
import {environment} from "../../../../environments/environment";

@Component({
    selector: 'app-authentication',
    standalone: true,
    imports: [],
    templateUrl: './authentication.component.html',
    styleUrl: './authentication.component.scss'
})
export class AuthenticationComponent implements OnInit {
    PORTAL = `${environment.PORTAL}`;

    constructor(
        private authService: AuthenticationService,
        private permissionFlag: PermissionFlagsService,
        private router: Router,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit(): void {
        this.autoLogin();
    }

    private autoLogin(): void {
        const authKey = this.route.snapshot.queryParamMap.get('auth-key');

        if (authKey) {
            console.log('Auth Key:', authKey);
            this.authService.login(authKey).subscribe({
                next: (isLoggedIn) => {
                    this.permissionFlag.refreshPermissions()
                    if (isLoggedIn) {
                        this.authService.homePage()
                    }
                },
                error: (error) => {
                    console.error('Login failed', error);
                }
            });
        } else {
            window.location.href = this.PORTAL;
            console.error('No auth-key found');
        }
    }
}
