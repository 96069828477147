import {Injectable, Signal} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {BehaviorSubject, map} from 'rxjs';
import {AuthenticationService} from "./authentication.service";

type AccessFlag = 'Pending' | 'Approved' | 'CheckedIn' | 'CheckedOut' | 'Rejected' | 'Canceled';
type PermissionFlag = 'Create' | 'Update' | 'Delete' | 'Approve' | 'Reject' | 'Cancel' | 'CheckIn' | 'CheckOut';
type FlagKey = `canAccess${AccessFlag}` | `can${PermissionFlag}Visitor`;

type ResourcePath = 'visitors' | 'visitor-create' | 'handle-visitors';

type FlagSignals = {
  [K in FlagKey]: Signal<boolean>;
};

interface PermissionConfig {
  flags: string[];
  contextMap?: {
    [key: string]: string;
  };
}

interface Permission {
  resourceId: number;
  resourceName: string;
  resourcePath: ResourcePath;
  context: string;
}

@Injectable({
  providedIn: 'root',
})
export class PermissionFlagsService {
  private permissionConfig: Record<ResourcePath, PermissionConfig> = {
    'visitors': {
      flags: [
        'canAccessPending',
        'canAccessApproved',
        'canAccessCheckedIn',
        'canAccessCheckedOut',
        'canAccessRejected',
        'canAccessCanceled'
      ],
    },
    'visitor-create': {
      flags: [
        'canCreateVisitor',
        'canUpdateVisitor',
        'canDeleteVisitor'
      ],
      contextMap: {
        'Create': 'canCreateVisitor',
        'Update': 'canUpdateVisitor',
        'Delete': 'canDeleteVisitor'
      },
    },
    'handle-visitors': {
      flags: [
        'canApproveVisitor',
        'canRejectVisitor',
        'canCancelVisitor',
        'canCheckInVisitor',
        'canCheckOutVisitor'
      ],
      contextMap: {
        'Approve': 'canApproveVisitor',
        'Reject': 'canRejectVisitor',
        'Cancel': 'canCancelVisitor',
        'Check-In': 'canCheckInVisitor',
        'Check-Out': 'canCheckOutVisitor'
      },
    }
  };

  private permissionsSubject = new BehaviorSubject<Permission[]>([]);
  permissions$ = this.permissionsSubject.asObservable();

  readonly flags: FlagSignals = Object.fromEntries(
    Object.values(this.permissionConfig)
      .flatMap((config) => config.flags)
      .map((flag) => [
        flag,
        toSignal(
          this.permissions$.pipe(
            map((permissions) => this.calculateFlagValue(flag as FlagKey, permissions))
          ),
          { initialValue: false }
        ),
      ])
  ) as FlagSignals;

  constructor(private auth: AuthenticationService) {
    this.loadAndUpdatePermissions();
  }

  private calculateFlagValue(flag: FlagKey, permissions: Permission[]): boolean {
    for (const permission of permissions) {
      const config = this.permissionConfig[permission.resourcePath];
      if (config) {
        if (permission.resourcePath === 'visitors' && flag.startsWith('canAccess')) {
          const accessType = flag.replace('canAccess', '');
          if (permission.context.includes(accessType)) {
            return true;
          }
        }
        if (config.contextMap) {
          const contextKey = Object.entries(config.contextMap).find(([, value]) => value === flag)?.[0];
          if (contextKey && permission.context.includes(contextKey)) {
            return true;
          }
        }
      }
    }
    return false;
  }

  loadAndUpdatePermissions(): void {
    const userInfoStr = sessionStorage.getItem(`${this.auth.APP_NAMESPACE}_user_info`);
    if (userInfoStr) {
      const userInfo = JSON.parse(userInfoStr);
      if (userInfo?.permissions) {
        this.updatePermissionFlags(userInfo.permissions);
      }
    }
  }

  updatePermissionFlags(permissions: Permission[]) {
    this.permissionsSubject.next(permissions);
  }

  refreshPermissions(): void {
    this.loadAndUpdatePermissions();
  }
}
