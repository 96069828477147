import {Routes} from '@angular/router';
import {VisitorCreateComponent, VisitorGridComponent} from "./modules";
import {AuthenticationComponent} from "./core/components/authentication/authentication.component";
import {authGuard} from "./core/guards/auth.guard";
import {permissionGuard} from "./core/guards/permission.guard";
import {NotFoundComponent} from "./core/components/redirect/redirect.component";

export const routes: Routes = [
  {
    path: '',
    component: AuthenticationComponent,
  },
  {
    path: 'authentication',
    component: AuthenticationComponent,
  },
  {
    path: 'visitors',
    component: VisitorGridComponent,
    canActivate: [authGuard, permissionGuard],
  },
  {
    path: 'visitor-create',
    component: VisitorCreateComponent,
    canActivate: [authGuard, permissionGuard],
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];
