import {Component, effect, EventEmitter, inject, OnDestroy, OnInit, Output, signal} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {LoadingService, NotificationService, PaginationComponent,} from "../../../../core";
import {UpdateVisitorStatusDTO, VisitorSearchResultDTO} from "../../interfaces/visitor.entity";
import {Subscription} from "rxjs";
import {VisitorService} from "../../services/visitor.service";
import {DatePipe, NgClass} from "@angular/common";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {VisitorCreateComponent} from "../visitor-create/visitor-create.component";
import {PermissionFlagsService} from "../../../../core/services/Authentication/permissionFlags.service";

type VisitorStatus = 'Approved' | 'Rejected' | 'CheckedIn' | 'CheckedOut' | 'Deleted' | 'Canceled';

@Component({
  selector: 'app-visitor-grid',
  standalone: true,
  imports: [FormsModule, PaginationComponent, NgClass, VisitorCreateComponent, RouterLink, DatePipe,],
  templateUrl: './visitor-grid.component.html',
  styleUrl: './visitor-grid.component.scss'
})
export class VisitorGridComponent implements OnInit, OnDestroy {

  @Output() pageChanged = new EventEmitter<number>();

  visitorService = inject(VisitorService);
  notification = inject(NotificationService);
  loading = inject(LoadingService);

  private route = inject(ActivatedRoute);
  private subscription: Subscription | undefined;
  private router = inject(Router);
  public permissionFlags: PermissionFlagsService = inject(PermissionFlagsService);

  visitorDTO: VisitorSearchResultDTO[] = []

  pageNumber = 1;
  totalItems: number = 0;
  itemsPerPage: number = 0;
  reason: string = '';
  searchText: string = '';
  cancelling: boolean = false;
  openModal = false;

  $$visitorId = signal(0);
  $$selectedVisitorId = signal(0);



  searchParams = {
    nic: '',
    type: '',
    name: '',
    status: '',
    items_per_page: '10',
    page_number: '1'
  };

  constructor() {
    effect(() => {
        const visitor = this.visitorService.active()
        if (visitor) {
          this.$$selectedVisitorId.set(visitor.id || 0);
        }
      }, {allowSignalWrites: true}
    );
    effect(() => {
      this.route.queryParams.subscribe(params => {
        if (!params['status']) {
          this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { status: 'pending' },
            queryParamsHandling: 'merge'
          });
        }
      });
    });
  }

  ngOnInit() {
    this.subscribeToQueryParams();

  }

  fetchVisitors() {
    if (this.searchParams.status == ''){
      return
    }
    this.loading.setLoading(true);
    this.visitorService.find(this.searchParams, true).subscribe({
      next: (response) => {
        this.visitorDTO = response?.data?.data ?? [];
        this.totalItems = response.data.totalItems;
        this.pageNumber = response.data.page;
        this.itemsPerPage = response.data.itemsPerPage;
        this.loading.setLoading(false);
      },
      error: (error) => {
        this.loading.setLoading(false);
        console.error(error);
        this.notification.showNotification({
          type: 'error',
          message: 'Failed to fetch visitors',
          timeout: 4000
        });
      }
    });
  }

  private getStatusMessage(status: VisitorStatus, visitorName: string): string {
    const messages = {
      Approved: `Appointment for ${visitorName} has been approved`,
      Rejected: `Appointment for ${visitorName} has been rejected`,
      CheckedIn: `Visitor ${visitorName} has checked in successfully`,
      CheckedOut: `Visitor ${visitorName} has checked out successfully`,
      Deleted: `Appointment for ${visitorName} has been deleted`,
      Canceled: `Appointment for ${visitorName} has been cancelled`
    };
    return messages[status];
  }

  private closeModalAndNotify(status: VisitorStatus, visitorName: string, isError: boolean = false) {
    this.onDiscard();
    this.openModal = false;
    const message = isError
      ? `Failed to update ${visitorName}'s status`
      : this.getStatusMessage(status, visitorName);
    this.notification.showNotification({
      type: isError ? 'error' : 'success',
      message: message,
    });
  }

  private updateVisitorStatus(status: VisitorStatus, reason?: string) {
    const selectedVisitor = this.visitorDTO.find(v => v.id === this.$$selectedVisitorId());
    const visitorName = selectedVisitor ? `${selectedVisitor.title} ${selectedVisitor.name}` : 'Unknown visitor';

    const updateVisitor: UpdateVisitorStatusDTO = {
      id: this.$$selectedVisitorId(),
      status: status,
      approvedBy: 'Manager',
      updatedBy: 'Admin',
      reason: reason
    };

    this.visitorService.updateStatus(updateVisitor, `status/${updateVisitor.id}`).subscribe({
      next: () => {
        this.fetchVisitors();
        this.closeModalAndNotify(status, visitorName);
        this.visitorService.initial()
      },
      error: (error) => {
        console.error('Error updating status:', error);
        this.closeModalAndNotify(status, visitorName, true);
      }
    });
  }

  approve() {
    this.updateVisitorStatus('Approved');
  }

  reject() {
    this.updateVisitorStatus('Rejected', this.reason);
  }

  checkIn() {
    this.updateVisitorStatus('CheckedIn');
  }

  checkOut() {
    this.updateVisitorStatus('CheckedOut');
  }

  delete() {
    this.updateVisitorStatus('Deleted');

  }

  cancel() {
    this.updateVisitorStatus('Canceled', this.reason);
  }

  private subscribeToQueryParams() {
    this.subscription = this.route.queryParams.subscribe(params => {
      this.searchParams.status = params['status'] || '';
      this.searchParams.page_number = params['page'] || '1';
      this.searchParams.items_per_page = params['items_per_page'] || '10';
      this.fetchVisitors();
    });
  }

  handleClick($visitorId: number) {
    this.$$visitorId.set($visitorId);
    console.log('Clicked visitor ID:', $visitorId);
    this.visitorService.getById(String(this.$$visitorId()), true).subscribe();
  }

  onSearchChange(value: string) {
    this.searchParams.name = '';
    this.searchParams.nic = '';

    if (/\d/.test(value)) {
      this.searchParams.nic = value;
    } else {
      this.searchParams.name = value;
    }
  }

  onPageChange(pageNumber: number) {
    this.searchParams = {
      ...this.searchParams,
      page_number: String(pageNumber)
    };
    this.fetchVisitors();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  closeModal() {
    this.openModal = false;
    this.cancelling = false;
    this.visitorService.initial()
  }

  clearSearch() {
    this.searchParams.nic = ''
    this.searchParams.type = ''
    this.searchParams.name = ''
    this.fetchVisitors()
  }

  onDiscard() {
    this.cancelling = false;
    this.reason = '';
  }

}

