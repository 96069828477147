import {CanActivateFn, Router} from '@angular/router';
import {inject} from '@angular/core';
import {PermissionFlagsService} from "../services/Authentication/permissionFlags.service";

export const permissionGuard: CanActivateFn = (route, state) => {
  const permissionFlags = inject(PermissionFlagsService);
  const router = inject(Router);

  const path = state.url.split('/')[1];

  switch (path) {
    case 'visitors':
      if (!permissionFlags.flags.canAccessPending()) {
        return router.createUrlTree(['/authentication']);
      }
      break;

    case 'visitor-create':
      if (!permissionFlags.flags.canCreateVisitor()) {
        return router.createUrlTree(['/visitors']);
      }
      break;
  }

  return true;
};
