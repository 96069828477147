<div class="min-h-screen w-full flex flex-col items-center justify-center  relative bg-white">
    <div class="absolute inset-0 z-0 opacity-20">
        <div class="absolute inset-0 bg-[radial-gradient(circle_at_1px_1px,#4F46E5_1px,transparent_0)] [background-size:40px_40px]"></div>
    </div>
    <main class="z-10 w-full max-w-md px-6 py-10">
        <div class="flex flex-col items-center space-y-6" aria-busy="true">
            <div class="relative">
                <div class="w-20 h-20 rounded-full border-4 border-blue-200"></div>
                <div class="absolute top-0 w-20 h-20 rounded-full border-4 border-t-blue-700 animate-spin"></div>
            </div>
            <div class="text-center space-y-3">
                <h2 class="text-2xl font-bold text-gray-800">
                    Loading...
                </h2>
                <div class="space-y-2">
                    <p class="text-gray-600">Verifying your credentials. This won't take long.</p>
                </div>
            </div>
        </div>
    </main>
</div>
