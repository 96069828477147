import {Component, computed, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router, RouterOutlet} from '@angular/router';
import {initFlowbite} from "flowbite";
import {AppHeaderComponent, LoadingService, NotificationService} from "./core";
import {DotAnimationComponent} from "./core/components/loading/dot-animation/dot-animation.component";
import {NotificationAlertComponent} from "./core/components/notification/notification-alert.component";
import {DotLoadingServices} from "./core/services/dot-loading.Services";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, AppHeaderComponent, DotAnimationComponent, NotificationAlertComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit ,OnDestroy {

  private subscriptions: Subscription = new Subscription();
  isLoading = computed(() => this.dotLoadingServices.isLoading());


  hideOnPages: boolean = false;

  constructor(
    private loadingService: LoadingService,
    private router: Router,
    public dotLoadingServices: DotLoadingServices,
    public notificationService: NotificationService,
  ) {
  }

  get notifications() {
    return this.notificationService.notification;
  }
  ngOnInit(): void {
    initFlowbite();
    this.subscriptions.add(
      this.loadingService.isLoading.subscribe((isLoading) => {
        this.dotLoadingServices.setLoading(isLoading);
      })
    );

    this.subscriptions.add(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.hideOnPages = this.router.url.includes('/login' && '/error' && '/authenticate');
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }


}
