import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {BehaviorSubject, catchError, distinctUntilChanged, map, Observable, tap, throwError} from "rxjs";
import {Router} from "@angular/router";
import {CachedAPIRequest} from "../../classes";
import {APIRequestResources} from "../../enums";
import {AuthResponse} from "../../types/userAuth.types";
import {environment} from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService extends CachedAPIRequest {
  private readonly API_URL = `${environment.API_BASE}/auth/login`;
  private readonly PORTAL_URL = `${environment.PORTAL}`;
  public readonly APP_NAMESPACE = 'vms'
  private readonly authState$ = new BehaviorSubject<boolean>(this.hasValidSession());

  readonly isAuthenticated$ = this.authState$.asObservable().pipe(
    distinctUntilChanged()
  );

  constructor(protected override http: HttpClient, protected router: Router) {
    super(http, APIRequestResources.AuthenticationService);
  }

  login(authKey: string): Observable<boolean> {
    const headers = new HttpHeaders().set('auth-key', authKey);

    return this.http.post<{ data: AuthResponse }>(this.API_URL, {}, { headers }).pipe(
      map(response => response.data),
      tap(authData => this.storeSession(authData)),
      map(() => true),
      catchError(error => {
        console.error('Authentication error:', error);
        this.clearSession();
        return throwError(() => new Error('Authentication failed'));
      })
    );
  }

  logout(): void {
    this.clearSession();
    window.location.href = this.PORTAL_URL;
  }

  private storeSession(authData: AuthResponse): void {
    if (!authData?.accessToken || !authData?.refreshToken) {
      throw new Error('Invalid authentication response');
    }

    sessionStorage.setItem(`${this.APP_NAMESPACE}_access_token`, authData.accessToken);
    sessionStorage.setItem(`${this.APP_NAMESPACE}_user_info`, JSON.stringify({
      userName: authData.username,
      permissions: authData.userPermissions
    }));

    this.authState$.next(true);
  }

  private clearSession(): void {
    sessionStorage.removeItem(`${this.APP_NAMESPACE}_access_token`);
    sessionStorage.removeItem(`${this.APP_NAMESPACE}_user_info`);
    this.authState$.next(false);
  }

  private hasValidSession(): boolean {
    return !!sessionStorage.getItem(`${this.APP_NAMESPACE}_access_token`);
  }

  public homePage(): void {
    this.router.navigate(['/visitors']);
  }

}
